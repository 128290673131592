@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
* {
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: black;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 1100px;
}

.navbar h1 {
  color: #5100FD;
}

.navbar .links {
  margin-left: auto;
}

.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
  font-weight: 700;
  color: black !important;
  font-size: 18px;
}

.navbar a:hover {
  color: #5100FD !important;
}

.content {
  margin: 20px auto;
  padding: 20px;
  max-width: 1100px;
}

.home{
  max-width: 1100px;
}

.navlogo{
  width: 10rem;
}

.heroimage{
  width: 32rem;
}


h1{
  font-family: 'Inter', sans-serif;
  color: #5100FD;
  font-weight: 900  !important;
}

h2{
  font-family: 'Inter', sans-serif;
  font-weight: 800  !important;
  padding-bottom: 15px;
  padding-top: 15px;
}

.sponsors h2{
  font-family: 'Inter', sans-serif;
  font-weight: 900  !important;
  color: white;
  padding-bottom: 20px;
}

p{
  font-family: 'Inter', sans-serif;
  color: black;
  font-weight: 500  !important;
}

.slogan{
  font-family: 'Inter', sans-serif;
  font-size: 10px !important;
}

.creatorImageOne{
  width: 16rem;
  -webkit-filter: drop-shadow(-5px 10px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(-5px 10px 4px rgba(0, 0, 0, 0.25));
}

.creatorImageTwo{
  width: 15rem;
  -webkit-filter: drop-shadow(-5px 10px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(-5px 10px 4px rgba(0, 0, 0, 0.25));
}

.Sponsor1{
  width: 125px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.Sponsor1:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.Sponsor2{
  width: 67px;
  margin-left: 25px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.Sponsor2:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.Sponsor3{
  width: 125px;
  margin-top: 5px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.Sponsor3:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.Sponsor4{
  width: 50px;
  margin-left: 35px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.Sponsor4:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}


.Sponsor5{
  width: 80px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.Sponsor5:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.Sponsor6{
  width: 80px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;

}

.Sponsor6:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.Sponsor7{
  width: 80px;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.Sponsor7:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.buttonOne{
  font-size: 20px; 
  border:4px solid #5100FD;
  text-decoration: none;
  padding: 15px;
  font-family:'Inter', sans-serif;
  text-align:"center";
  width: 50%;
  margin: 0 auto;
  font-weight: 600;
  color: #5100FD;
  box-sizing: border-box;
  box-shadow: 0px 0px 32px 10px rgba(0, 0, 0, 0.15);
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.buttonOne:hover{
  color: black !important;
  border: 4px solid black;
}

.sponsors{
  background-color: #5100FD;
  position:absolute;
  left:0;
  right:0;
}

.container{
  border-color: black;
}

.text-center{
  padding-top: 25px;
}

.heroText{
  padding-top: -30px;
}

.aboutText{
  text-align: left;
}

.sponsorHeader{
  padding-left: 100px;
  padding-top: 20px;
}

.signIn{
  align-items: center;
  width: 100%;  
  height: 100%; 
}

.playerImage{
  position: absolute;
  width: 494px;
  height: 400px;
  left: 86px;
  top: 348px;
  border: 5px solid #5100FD;
  box-shadow: 0px 0px 32px 15px rgba(0, 0, 0, 0.19), inset 0px 400px 100px rgba(0, 0, 0, 0.4), inset 0px -400px 100px rgba(0, 0, 0, 0.4);
}

.Col{
  padding: 0;
}

.bg-dark{
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  border-radius: 0% !important;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
}

.bg-dark:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.cardTitle{
  color: white;
  font-family: 'Inter', sans-serif !important;
  font-size: 45px !important;
  font-weight: 800 !important;
  padding-bottom: 92%;
}

.cardText{
  color: white;
  font-family: 'Inter', sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.playerTitle{
  color: white;
  font-family: 'Inter', sans-serif !important;
  font-size: 25px !important;
  font-weight: 600 !important;
  padding-bottom: 51%;
}

.playerText{
  color: white;
  font-family: 'Inter', sans-serif !important;
  font-size: 15px;
}

.shape1{
  width: 150px;
  height: 150px;
  border-top-right-radius:100%;
  border-top-left-radius:0;
  border-bottom-right-radius:0;
  border-bottom-left-radius:0;
  left: -110px;
  top: 294px;
  position: absolute;
  background: #5100FD;
}

.shape2{
  width: 150px;
  height: 150px;
  border-top-right-radius:0;
  border-top-left-radius:100%;
  border-bottom-right-radius:0;
  border-bottom-left-radius:0;
  left: 1020px;
  top: 294px;
  position: absolute;
  background: #5100FD;
}

.shape3{
  width: 150px;
  height: 150px;
  border-top-right-radius:100%;
  border-top-left-radius:100%;
  border-bottom-right-radius:100%;
  border-bottom-left-radius:100%;
  left: 100px;
  top: 270px;
  position: absolute;
  background: #5100FD;
}

.shape4{
  width: 250px;
  height: 250px;
  border-top-right-radius:100%;
  border-top-left-radius:100%;
  border-bottom-right-radius:100%;
  border-bottom-left-radius:100%;
  left: 350px;
  top: 400px;
  position: absolute;
  background: #5100FD;
}

.shape5{
  width: 600px;
  height: 600px;
  border-top-right-radius:100%;
  border-top-left-radius:100%;
  border-bottom-right-radius:100%;
  border-bottom-left-radius:100%;
  left: 500px;
  top: 100px;
  position: absolute;
  background: #5100FD;
}

.shape6{
  width: 650px;
  height: 650px;
  border-top-right-radius:100%;
  border-top-left-radius:100%;
  border-bottom-right-radius:100%;
  border-bottom-left-radius:100%;
  left: 450px;
  top: 100px;
  position: absolute;
  background: #5100FD;
}

.shape7{
  width: 200px;
  height: 200px;
  border-top-right-radius:100%;
  border-top-left-radius:100%;
  border-bottom-right-radius:100%;
  border-bottom-left-radius:100%;
  left: 10px;
  top: 400px;
  position: absolute;
  z-index:-1;
  background: #5100FD;
}

.shape8{
  width: 300px;
  height: 300px;
  border-top-right-radius:100%;
  border-top-left-radius:100%;
  border-bottom-right-radius:100%;
  border-bottom-left-radius:100%;
  left: 900px;
  top: 700px;
  position: absolute;
  z-index:-1;
  background: #5100FD;
}

.shape9{
  width: 150px;
  height: 150px;
  border-top-right-radius:100%;
  border-top-left-radius:100%;
  border-bottom-right-radius:100%;
  border-bottom-left-radius:100%;
  left: 300px;
  top: 600px;
  position: absolute;
  z-index:-1;
  background: #5100FD;
}

.shape10{
  width: 150px;
  height: 150px;
  border-top-right-radius:100%;
  border-top-left-radius:0;
  border-bottom-right-radius:100%;
  border-bottom-left-radius:0;
  left: -110px;
  top: 270px;
  position: absolute;
  background: #5100FD;
}

.shape11{
  width: 150px;
  height: 150px;
  border-top-right-radius:0;
  border-top-left-radius:100%;
  border-bottom-right-radius:0;
  border-bottom-left-radius:0;
  left: 1113px;
  top: 725px;
  position: absolute;
  background: #5100FD;
}

.shape12{
  width: 150px;
  height: 150px;
  border-top-right-radius:0;
  border-top-left-radius:100%;
  border-bottom-right-radius:0;
  border-bottom-left-radius:0;
  left: 1130px;
  top: 459px;
  position: absolute;
  background: #5100FD;
}

.hider{
  overflow: hidden;
}

.parent{
  position: relative;
}

.exploreTab{
  text-decoration: none;
  color: black;
}

.exploreTab:hover{
  color: #5100FD;
}

.testyImages{
  border-radius: 0% !important;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.testies{
  font-family: 'Inter', sans-serif;
  font-size: 25px;
  text-align: center;
  padding-top: 105px;
  font-weight: 800 !important;
  line-height: 175%;
}

.testyImages:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.names{
  font-family: 'Inter', sans-serif;
  font-size: 25px;
  text-align: center;
  color: #5100FD;
  font-weight: 900 !important;
}

.formLabel{
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color:#5100FD;
} 
::selection { 
  background-color:#5100FD;
  color: #fff; 
}

.form-control{
  border-color: black !important;
  border-width: 2px !important;
}


.form-control:focus {
  border-color: #5100FD !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #5100FD !important;
}

.submitButton{
  background-color: white!important;
  color: #5100FD !important;
  font-family: inherit;
  border-color: #5100FD !important;
  border-width: 4px !important;
  padding: 1opx !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-align: center !important;
  margin:0 auto !important;
  display:block !important;
  box-shadow: 0px 0px 32px 10px rgba(0, 0, 0, 0.15);
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.submitButton:hover{
color: black !important;
border: 4px solid black !important;
}

.links{
  text-decoration: none;
  color: #5100FD;
}

.links:hover{
  color: black;
}

